import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material'

const NewDeviceDialog = ({ site, open, onClose, onSubmit }) => {
  const [deviceType, setDeviceType] = useState('')
  const [deviceName, setDeviceName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [ip, setIp] = useState('')

  const handleSubmit = () => {
    const device = buildDeviceObject(
      deviceType,
      deviceName,
      username,
      password,
      ip
    )
    onSubmit(device)
    onClose()
  }

  const buildDeviceObject = (type, deviceName, username, password, ip) => {
    const deviceMapping = {
      mikrotikRouter: { family: 'mikrotik', function: 'router' },
      mikrotikSwitch: { family: 'swos', function: 'switch' },
      edgeswitch: { family: 'edgeswitch', function: 'switch' },
      netonix: { family: 'netonix', function: 'switch' },
      edgepower: { family: 'edgepower', function: 'psu' },
      alg: { family: 'alg', function: 'psu' },
      ict: { family: 'ict', function: 'psu' },
      ltu: { family: 'ltu', function: 'ap-ptmp' },
      wave: { family: 'wave', function: 'ap-ptmp' },
      epmp: { family: 'epmp', function: 'ap-ptmp' },
      airmax: { family: 'airmax', function: 'ap-ptmp' },
      'airfiber-60': { family: 'airfiber-60', function: 'ptp' },
      af60: { family: 'af60', function: 'ptp' },
      af5xhd: { family: 'af5xhd', function: 'ptp' },
      airFiber: { family: 'airFiber', function: 'ptp' },
      aviat: { family: 'aviat', function: 'ptp' },
    }

    const baseDevice = {
      site: site._id,
      name: deviceName,
      ip: ip,
      username: username,
      password: password,
    }

    return { ...baseDevice, ...deviceMapping[type] }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Device</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Device Type</InputLabel>
          <Select
            value={deviceType}
            onChange={(e) => setDeviceType(e.target.value)}
          >
            <MenuItem value={'mikrotikRouter'}>Mikrotik Router</MenuItem>
            <MenuItem value={'mikrotikSwitch'}>Mikrotik Switch</MenuItem>
            <MenuItem value={'netonix'}>Netonix Switch</MenuItem>
            <MenuItem value={'edgeswitch'}>Ubiquiti EdgeSwitch</MenuItem>
            <MenuItem value={'edgepower'}>Ubiquiti EdgePower</MenuItem>
            <MenuItem value={'alg'}>ALG</MenuItem>
            <MenuItem value={'ict'}>ICT</MenuItem>
            <MenuItem value={'ltu'}>LTU AP</MenuItem>
            <MenuItem value={'wave'}>Wave AP</MenuItem>
            <MenuItem value={'epmp'}>ePMP AP</MenuItem>
            <MenuItem value={'airmax'}>airMAX AP</MenuItem>
            <MenuItem value={'airfiber-60'}>AF60-XR</MenuItem>
            <MenuItem value={'af60'}>AF60</MenuItem>
            <MenuItem value={'af5xhd'}>AF5XHD</MenuItem>333
            <MenuItem value={'airFiber'}>airFiber (af11)</MenuItem>
            <MenuItem value={'aviat'}>Aviat</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="dense"
          label="Name"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value)}
        />
        <TextField
          fullWidth
          margin="dense"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          fullWidth
          margin="dense"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          fullWidth
          margin="dense"
          label="IP Address"
          value={ip}
          onChange={(e) => setIp(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewDeviceDialog
