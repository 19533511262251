import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetSite } from '../reduxSlices/wsSiteSlice'
import { resetSites } from '../reduxSlices/wsSitesSlice'
import { resetDevices } from '../reduxSlices/wsDevicesSlice'
import { resetDevice } from '../reduxSlices/wsDeviceSlice'
import { resetNoiseData } from '../reduxSlices/wsNoiseDataSlice'
import { resetDataLinks } from '../reduxSlices/dataLinkSlice'
import { useAppContext } from '../context/AppProvider'
import { resetEvents } from '../reduxSlices/wsEventsSlice'
import { resetUsers } from '../reduxSlices/wsUsersSlice'
import { resetUser } from '../reduxSlices/userSlice'
import { resetSubscriber } from '../reduxSlices/subscriberSlice'
import { resetDispatchables } from '../reduxSlices/dispatchablesSlice'

export const useGeolocation = () => {
  const [state, setState] = useState({
    accuracy: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    heading: 0,
    latitude: 0,
    longitude: 0,
    speed: 0,
    timestamp: Date.now(),
  })
  let mounted = true
  let watchId

  const e = () => {
    return "Couldn't do GPS"
  }

  const onEvent = (event) => {
    if (mounted) {
      setState({
        accuracy: event.coords.accuracy,
        altitude: event.coords.altitude,
        altitudeAccuracy: event.coords.altitudeAccuracy,
        heading: event.coords.heading,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
        speed: event.coords.speed,
        timestamp: event.timestamp,
      })
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onEvent, e, {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 0,
    })
    watchId = navigator.geolocation.watchPosition(onEvent)

    return () => {
      mounted = false
      navigator.geolocation.clearWatch(watchId)
    }
  }, [0])
  return state
}

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useCreateUrl = (data) => {
  useEffect(() => {
    const { family, function: deviceFunction, _id, uplink } = data
    let url = ''
    switch (family) {
      case 'ltu':
        switch (deviceFunction) {
          case 'ap-ptmp':
            url = `/network/device/${_id}`
            break
          case 'sta-ptmp':
            url = `/network/device/${uplink}/${_id}`
            break
        }
        break
      case 'edgepower':
        break
      case 'mikrotik':
        switch (deviceFunction) {
          case 'router':
            break
          case 'switch':
            break
        }
      case 'edgeswitch':
        break
    }
    return url
  }, [data])
}

export const useUptime = (uptime) => {
  const plural = (value, unit) => {
    if (value === 1) {
      return value + ' ' + unit
    } else if (value > 1) {
      return value + ' ' + unit + 's'
    }
  }
  const fm = [
    Math.floor(uptime / (3600 * 24)),
    Math.floor((uptime % (3600 * 24)) / 3600),
    Math.floor((uptime % 3600) / 60),
    Math.floor(uptime % 60),
  ]
  const formattedUptime = () =>
    fm
      .map((el, i) => {
        if (Boolean(el)) {
          if (i === 0) {
            el = el + 'd'
          } else if (i === 1) {
            el = el + 'h'
          } else if (i === 2) {
            el = el + 'm'
          } else if (i === 3) {
            el = el + 's'
          }
          return el
        }
      })
      .join('')
  return formattedUptime()
}

export const useGetClients = () => {
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchClients()
  }, [])
}

export const useGetSubscriber = (id) => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchSubscriber(id)
    return () => {
      dispatch(resetSubscriber())
    }
  }, [id])
}

export const useGetDevices = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchDevices()
    return () => {
      dispatch(resetDevices())
    }
  }, [])
}

export const useGetUser = (id) => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchUser(id)
    return () => {
      dispatch(resetUser())
    }
  }, [])
}

export const useGetUsers = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchUsers()
    return () => {
      dispatch(resetUsers())
    }
  }, [])
}

// export const useGetDevice = (apId, staId) => {
//   const dispatch = useDispatch()
//   const { ws } = useAppContext()
//   useEffect(() => {
//     ws.fetchDevice(apId)
//     ws.fetchEvents({ device: staId ? staId : apId })
//     return () => {
//       dispatch(resetDevice())
//       dispatch(resetNoiseData())
//       dispatch(resetEvents())
//     }
//   }, [apId, staId])
// }

export const useGetDevice = (id) => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchDevice(id)
    return () => {
      dispatch(resetDevice())
      dispatch(resetNoiseData())
      dispatch(resetEvents())
    }
  }, [id])
}

export const useGetEvents = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchEvents({ all: true })
    return () => {
      dispatch(resetEvents())
    }
  }, [])
}

export const useJoinDeviceRoom = (id) => {
  const { ws } = useAppContext()
  useEffect(() => {
    console.log(id)
    if (id) {
      ws.joinDeviceRoom(id)
      return () => {
        ws.leaveDeviceRoom(id)
      }
    }
  }, [id])
}

export const useJoinSocketRoom = () => {
  const { ws } = useAppContext()
  useEffect(() => {
    if (ws) {
      ws.joinSocketRoom()
      return () => {
        ws.leaveSocketRoom()
      }
    }
  }, [ws])
}

export const useJoinSiteRoom = (id) => {
  const { ws } = useAppContext()
  useEffect(() => {
    ws.joinSiteRoom(id)
    return () => {
      ws.leaveSiteRoom(id)
    }
  }, [id])
}

export const useJoinSitesListRoom = () => {
  const { ws } = useAppContext()
  useEffect(() => {
    ws.joinSitesListRoom()
    return () => {
      ws.leaveSitesListRoom()
    }
  }, [])
}

export const useStaTunnel = (apId, sta) => {
  console.log(sta)
  const { ws } = useAppContext()
  useEffect(() => {
    if (apId && sta && apId !== sta?._id) {
      ws.joinTunnelRoom(apId, sta)
      return () => {
        ws.leaveTunnelRoom(apId, sta)
      }
    }
  }, [apId, sta?._id])
}

export const useApTunnel = (ap) => {
  const { ws } = useAppContext()
  useEffect(() => {
    ap?.family === 'ltu' && ws.fetchApTunnel(ap.ip)
    return () => {
      ap?.family === 'ltu' && ws.fetchApTunnelClose()
    }
  }, [ap.ip])
}

export const useGetLiveNoiseData = (host) => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    if (host) {
      ws.fetchNoiseData(host)
      return () => {
        ws.stopNoiseData(host)
        dispatch(resetNoiseData())
      }
    }
  }, [host])
}

export const useGetSites = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchSites()
    return () => {
      dispatch(resetSites())
    }
  }, [])
}
export const useGetDispatchables = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchDispatchables()
    return () => {
      dispatch(resetDispatchables())
    }
  }, [])
}

export const useGetSite = (id) => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchSite(id)
    ws.fetchEvents({ site: id })
    return () => {
      dispatch(resetSite())
    }
  }, [id])
}

export const useGetDataLinks = () => {
  const dispatch = useDispatch()
  const { ws } = useAppContext()
  useEffect(() => {
    ws.fetchDataLinks()
    return () => {
      dispatch(resetDataLinks())
    }
  }, [])
}

export const useSingleAndDoubleClick = (
  actionSimpleClick,
  actionDoubleClick,
  delay = 250
) => {
  const [click, setClick] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick()
      setClick(0)
    }, delay)

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick()

    return () => clearTimeout(timer)
  }, [click])

  return () => setClick((prev) => prev + 1)
}

export const useApFinder = async (latlng) => {
  const { ws } = useAppContext()
  let result
  const [state, setState] = useState({
    aps: [],
    loading: false,
    error: null,
  })

  if (latlng) {
    result = await ws.fetchBestAps(latlng)
    console.log(result)
  }

  //useEffect(() => {
  //  if (latlng) {
  //
  //    setState({
  //      aps: [],
  //      loading: true,
  //      error: null
  //    })
  //
  //
  //
  //  }
  //}, [])

  //return state
}
